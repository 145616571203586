$primary: #215732;
$primaryLight: #DAF9E4;
$default: #212529;
$fontPrimary: #000;
$fontSecondary: #303030;
$fontTertiary: #525252;
$fontQuaternary: #a8a8a8;
$background: #F5F5F7;
$off-white: #f4f4f4;
$gray:#f8fafc;
$white: #FFF;
$light-gray: #9e9b9b;
$bgHover: #e7e7e732;
$bgHover2: #e7e7e748;
$bgHoverSecondary: #3f3f3f;
$bgHoverPrimary: #1b3624;
$gradientGreen: linear-gradient(#00986C, #006D46);

$green: #29a745;
$danger: #dc3545;
$warning: #ffc107;
$info: #62B1F6;

$checkBox: #446c3c;
$checkBoxCheked: #285627;
$checkBoxDisabled: #6d8b6b;

$boxShadow: rgba(171, 171, 171, .25);