@import './color';
@import './typography';

body {
  overflow: hidden;
  background-color: #FFF;
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  font-family: $rubik !important;
  line-height: 19px;
  padding: 0 !important;
}

.custom-card {
  padding: 14px;
  background: $white;
  box-shadow: 0px 0px 20px $boxShadow;
  border-radius: 10px;
  align-items: stretch;
}

.content-info  {
  h1 {
    font-size: 1.563rem !important;
    font-weight: 600 !important;
    margin-bottom: 0.5rem;
  }

  h2 {  font-size: 1.375rem !important; }
  h3 { font-size: 1.25rem !important; }

  h1, h2, h3  { text-transform: uppercase; }

  h2, h3 {
    margin-top: 20px;
    margin-bottom: 0.5rem;
  }

  p {
    font-size: .875rem !important;
    margin: 0;
    text-align: justify;
  }
}

.close { 
  cursor: pointer;
  font-size: 25px !important; 
}

.circle { 
  width: 10px; 
  height: 10px; 
  border-radius: 50%;
}

.date-selector {
  p { 
    font-size: 0.875rem !important; 
    text-transform: capitalize;
  }
}

.MuiPickersDay-today, .MuiPickersDay-today.Mui-disabled {
  background-color: $primaryLight !important;
}