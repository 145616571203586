@import '../../../../../../../styles/base/color';

.documentos-list {
    .title {
        font-size: 0.675em !important;
        padding-bottom: 15px;
        border-bottom: 1px solid $fontSecondary;

        h1 {
            font-size: 1.2rem !important;
            color: $fontSecondary !important;
        }
        
        small {  font-size: 0.675rem; }
    }

    .comunes {
        > div > div:first-child {
            font-weight: 500;
            box-shadow: 0px 0px 10px $boxShadow;
        }
    }

    .folder {
        cursor: pointer;
        border: 1px solid $light-gray;
        border-radius: 10px;
        padding: 15px 30px;
        font-size: 1.2rem !important;
        color: $fontSecondary !important;
        box-shadow: 0px 0px 10px $boxShadow;
        
        p { font-size: 1.2rem !important; } 

        small {
            font-size: 0.675rem !important;
            text-transform: uppercase;
        }
    }

    &-item {
        & .empleado { font-size: 1rem !important; }

        & .doc {
            p {
                font-size: 0.675rem !important;
                margin-top: 5px;
            }
        }
    }
}