@import '../../../../../../styles/base/color';


.calendar {
    .weekday p {
        font-size: 0.75rem !important;
    }

    tbody tr {
        height: 90px;
    }

    .calendar-day {
        height: inherit;
        background-color: $light-gray;
        p { color: $white !important; } 

        &.current { 
            background-color: #F5F5F7 !important;

            p {  color: $fontPrimary !important; }
        }

        &.today {
            background-color: $primary !important;
            p { 
                color: $white !important; 
                font-weight: 700 !important;
            } 
        }

        &.weekend {
            background-color: #b2b2b2cb !important;
        }

        &.holiday {
            background-color: #a8f8c1 !important;
        }

        .action { font-size: 1rem; }
    }
}