@import '../../../../styles/main.scss';

.change-password {
  padding-top: 36px;
  &__form {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    gap: 16px;
    padding: 46px 50px;
    background-color: $background;
    border-radius: 6px;

    &--each-password {
      position: relative;

      label {
        color: $primary !important;
        font-weight: 500 !important;
      }

      input {
        border: none;
        margin-top: 6px;
      }
      svg {
        position: absolute;
        right: 10px;
        bottom: 14px;
        font-size: 13px;
        cursor: pointer;
        color: $primary;
      }
    }

    button {
      border-radius: 4px !important;
      font-weight: 400 !important;
    }
  }
}