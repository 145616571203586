@import '../base/color';

.form-group label, label {
  text-transform: uppercase;
  font-size: 0.75rem;
  font-weight: 700;
}

textarea { background-color: $white !important; }

/* INPUT */
fieldset.MuiOutlinedInput-notchedOutline {
  border: 1px solid $primary !important;
}

.css-hdw1oc{ display: none !important; }

.MuiAutocomplete-endAdornment { top: unset !important; }
.MuiOutlinedInput-root .MuiAutocomplete-input { border: none !important; }


input[type=text], 
input[type=search], 
input[type=tel], 
input[type=password], 
input[type=email], 
input[type=number], 
input[type=date],
.form-control, 
.form-control[readonly] {
  min-width: 95px !important;
  border: 1px solid $primary;
  font-size: 0.875rem !important;
  font-weight: 300 !important;
  padding: 10px 30px 10px 10px !important;
  border-radius: 5px !important;
  background-color: $white;
  color: #212529 !important;

  @media screen and (max-width: 768px) {
    & { font-size: 0.875rem !important; }
  }

  &::placeholder { color: $primary !important; opacity: .8; }

  &:focus {
    border-color: $primary !important;
    box-shadow: none !important;
  }
}

.MuiInputBase-root input { border: none !important; }
.MuiOutlinedInput-root { 
  padding: 0 40px 0 0 !important; 
}
.MuiAutocomplete-inputRoot.MuiAutocomplete-input { width: auto !important; }

.css-6hp17o-MuiList-root-MuiMenu-list .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
  display: flex !important;
  justify-content: flex-start !important;
  align-items: center !important;
  padding: 6px 16px;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  cursor: pointer;
  vertical-align: middle;
  line-height: 1.5;
  letter-spacing: 0.00938em;
}

input[type=number] { padding-right: 0 !important; }

input.form-control-sm {
  height: 32px !important;
  padding: 8px 8px 6px;
}

.date-picker {
  position: relative;
  display: flex;
  align-items: center;

  & > div { 
    position: absolute;
    right: 12px;
    bottom: 20px;
  }
}

select, textarea {
  width: 100%;
  border: 1px solid #215732 !important;
  font-size: 0.875rem !important;
  font-weight: 300 !important;
  padding: 10px 20px 10px 10px !important;
  border-radius: 5px !important;
  background-color: $white;
}
select { position: relative; }
select::after 
{
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}
select.open:after {
  -webkit-transform: rotate(-135deg);
  -ms-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

// SEARCH INPUT

.input-group {
  .search-input {
    margin-left: 0 !important;
    border-radius: 16px !important;
    background-color: var(--table-primary) !important;
    padding-left: 44px !important;
    position: relative;
    z-index: 0 !important;
  }
  
  .search-input-icon {
    position: absolute;
    top: 8px;
    left: 15px;
    width: 16px;
    height: 16px;
    z-index: 1;
  }
}

.position-relative {
  .icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.radiobutton-group {
  margin-bottom: 6px !important;
  
  &-text { font-size: 12px; }
}

input[type='radio'], input[type='checkbox'] {
  accent-color: $primary !important;
}
