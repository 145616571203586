@import '../../../../styles/base/color';


.dashboard {
    position: relative;
    
    .summary {
        h1 { 
            font-size: 1.25rem; 
            font-weight: 700;
        }    
    }
}